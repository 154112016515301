import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, FieldSet, Input, useStyles2 } from '@grafana/ui';

import { useAppDispatch, useAppSelector } from 'feature/common/store';
import { useEditConfigurationSteps } from 'feature/remote-configuration/hooks/useEditConfigurationSteps';
import { onSetContents, onSetName } from 'feature/remote-configuration/store/EditConfigurationSlice';
import { AlloyEditorAnnotations } from 'feature/remote-configuration/types/AlloyEditorAnnotation';

import { AlloyCodeEditor } from './code-editor/AlloyCodeEditor';

export const ConfigurationForm = ({ title }: { title: string }) => {
  const styles = useStyles2(getStyles);
  const configuration = useAppSelector((state) => state.editConfiguration.data);
  const dispatch = useAppDispatch();
  const { setAnnotations } = useEditConfigurationSteps();

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(onSetName(event.currentTarget.value));
  }

  function onContentChange(value: string) {
    dispatch(onSetContents(value));
  }

  function onValidate(annotations: AlloyEditorAnnotations[]) {
    dispatch(setAnnotations(annotations));
  }

  return (
    <FieldSet label={title} className={styles.ct}>
      <Field label="Name" className={styles.name}>
        <Input name="name" onChange={onNameChange} value={configuration.name} />
      </Field>
      <Field label="Add and edit configuration snippets below">
        <AlloyCodeEditor onValidate={onValidate} onChange={onContentChange} value={configuration.contents} />
      </Field>
    </FieldSet>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    name: css({
      width: '50%',
    }),
    ct: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    }),
  };
}
