import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WizardSteps } from '../../common/types/Wizard';
import { AlloyEditorAnnotations } from '../types/AlloyEditorAnnotation';

export const DEFINE_CONFIGURATION = 0;
export const MATCH_TO_COLLECTORS = 1;

export const editConfigurationSteps: WizardSteps = {
  [DEFINE_CONFIGURATION]: {
    name: 'Define configuration',
  },
  [MATCH_TO_COLLECTORS]: {
    name: 'Match to collectors',
  },
};

export interface PipelineData {
  name: string;
  id?: string;
  matchers: string[];
  contents: string;
  enabled: boolean;
}

interface EditConfigurationState {
  step: number;
  data: PipelineData;
  steps: WizardSteps;
  tested: boolean;
  annotations: AlloyEditorAnnotations[];
}

const initialState: EditConfigurationState = {
  step: DEFINE_CONFIGURATION,
  data: {
    name: '',
    matchers: [],
    contents: '',
    enabled: false,
  },
  tested: false,
  steps: editConfigurationSteps,
  annotations: [],
};

export const EditConfigurationSlice = createSlice({
  name: 'edit-configuration-slice',
  initialState,
  reducers: {
    onNextStep(state) {
      return {
        ...state,
        step: state.step + 1,
      };
    },
    onPrevStep(state) {
      if (state.step === 0) {
        return state;
      }
      return {
        ...state,
        step: state.step - 1,
      };
    },
    onSetName(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          name: action.payload,
        },
        tested: false,
        steps: {
          ...state.steps,
          [DEFINE_CONFIGURATION]: {
            ...state.steps[DEFINE_CONFIGURATION],
            done: false,
          },
        },
      };
    },
    onSetMatchers(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        data: {
          ...state.data,
          matchers: action.payload,
        },
        steps: {
          ...state.steps,
          [MATCH_TO_COLLECTORS]: {
            ...state.steps[MATCH_TO_COLLECTORS],
            done: action.payload.length > 0,
          },
        },
      };
    },
    onSetAnnotations(state, action: PayloadAction<AlloyEditorAnnotations[]>) {
      return {
        ...state,
        annotations: action.payload,
      };
    },
    onSetContents(state, action: PayloadAction<string>) {
      return {
        ...state,
        data: {
          ...state.data,
          contents: action.payload,
        },
        tested: false,
        steps: {
          ...state.steps,
          [DEFINE_CONFIGURATION]: {
            ...state.steps[DEFINE_CONFIGURATION],
            done: false,
          },
        },
      };
    },
    onSetData(state, action: PayloadAction<{ pipeline: PipelineData; tested: boolean }>) {
      return {
        ...state,
        data: action.payload.pipeline,
        tested: action.payload.tested,
        annotations: [],
        steps: {
          ...state.steps,
          [MATCH_TO_COLLECTORS]: {
            ...state.steps[MATCH_TO_COLLECTORS],
            done: action.payload.pipeline.matchers.length > 0,
          },

          [DEFINE_CONFIGURATION]: {
            ...state.steps[DEFINE_CONFIGURATION],
            done: action.payload.tested,
          },
        },
      };
    },
    onSetTested(state) {
      return {
        ...state,
        tested: true,
        annotations: [],
        steps: {
          ...state.steps,
          [DEFINE_CONFIGURATION]: {
            ...state.steps[DEFINE_CONFIGURATION],
            done: true,
          },
        },
      };
    },
    onResetWizard() {
      return { ...initialState };
    },
  },
});

export const {
  onNextStep,
  onPrevStep,
  onSetData,
  onResetWizard,
  onSetContents,
  onSetTested,
  onSetMatchers,
  onSetName,
  onSetAnnotations,
} = EditConfigurationSlice.actions;
