import { css } from '@emotion/css';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, Icon, Input, Link, LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { Pipeline, listPipelines, useQuery } from '@grafana-cloud/fleet-management-api';

import { filterAndSortPipelines } from '../../utils/pipelines';

import { ConfigurationList } from './ConfigurationList';
import { DeleteConfigurationModal } from './DeleteConfigurationModal';
import { ToggleActiveModal } from './ToggleActiveModal';

export const RemoteConfiguration: React.FC = () => {
  const { data, isPending, error, isSuccess } = useQuery(listPipelines);

  const [filter, setFilter] = useState<string>('');

  const [removePipeline, setRemovePipeline] = useState<Pipeline | undefined>();
  const [toggleActive, setToggleActive] = useState<Pipeline | undefined>();

  const filteredData = useMemo(() => filterAndSortPipelines(data?.pipelines ?? [], filter), [data, filter]);
  const styles = useStyles2(getStyles);

  const navigate = useNavigate();

  function onCreate() {
    navigate('./create-configuration');
  }
  function onUpdate(pipeline: Pipeline) {
    navigate('./update-configuration/' + pipeline.id);
  }
  function onRemove(pipeline: Pipeline) {
    setRemovePipeline(pipeline);
  }

  function onActiveChange(pipeline: Pipeline, checked: boolean) {
    setToggleActive(pipeline);
  }
  return (
    <>
      <Link href="#" className={styles.docsLink} target="_blank">
        Documentation
      </Link>
      <div className={styles.search}>
        <Input
          onChange={(ev: React.FormEvent<HTMLInputElement>) => setFilter(ev.currentTarget.value)}
          prefix={<Icon name="search" />}
          placeholder="Search by configuration name or matching attributes"
          aria-label="Search configurations"
        />
        <Button icon={'plus'} onClick={onCreate}>
          Create configuration
        </Button>
      </div>

      <h3 className={styles.configurationListTitle}>Configuration list</h3>

      {!!error && (
        <Alert severity="error" title="Error when loading configurations">
          {error.message}
        </Alert>
      )}

      {isSuccess && (
        <ConfigurationList
          pipelines={filteredData}
          onActiveChange={onActiveChange}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      )}

      {removePipeline && (
        <DeleteConfigurationModal pipeline={removePipeline} onClose={() => setRemovePipeline(undefined)} />
      )}

      {toggleActive && <ToggleActiveModal pipeline={toggleActive} onClose={() => setToggleActive(undefined)} />}
      {isPending && <LoadingPlaceholder text="Loading..." />}
    </>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    docsLink: css({
      marginBottom: theme.spacing(2),
      display: 'block',
    }),
    search: css({
      marginBottom: theme.spacing(2),
      display: 'flex',
      columnGap: theme.spacing(1),
    }),
    configurationListTitle: css({
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    }),
  };
}
