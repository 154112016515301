import React, { lazy, Suspense } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import { addComponent } from '@grafana-cloud/ui';
import { CollectorInstallationInstructionsExtensionProps } from 'extensions/CollectorInstallationInstructionsExtension';
import { AppJSONData } from 'feature/common/types/AppJSONData';

import { ComingSoon } from './feature/app/components/ComingSoon';
import { isCollectorAppEnabled } from './feature/common/utils/featureToggles';

const LazyApp = lazy(() => import('./feature/app/components/App'));

const App = (props: AppRootProps<AppJSONData>) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

const LazyCollectorInstallationInstructionsExtension = React.lazy(
  () => import('extensions/CollectorInstallationInstructionsExtension')
);

const CollectorInstallationInstructionsExtension = (props: CollectorInstallationInstructionsExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyCollectorInstallationInstructionsExtension {...props} />
  </Suspense>
);

export const plugin = new AppPlugin<AppJSONData>().setRootPage(isCollectorAppEnabled() ? App : ComingSoon);

addComponent<CollectorInstallationInstructionsExtensionProps>(plugin, {
  title: 'Collector Installation Instructions',
  description: 'UI for displaying collector installation instructions.',
  targets: 'grafana/grafana-collector-app/collector-installation-instructions/v1',
  component: CollectorInstallationInstructionsExtension,
});
